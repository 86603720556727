import Cookies from 'universal-cookie'
import React, { useState, useEffect } from 'react';
import WriteLikeChatGPT from 'write-like-chat-gpt';
import { Input, Button, Form, Loader, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

export const PrimaryColor = '#b51f27'
export const PrimaryButton = styled(Button)`
    background-color: ${PrimaryColor} !important;
    color: white !important;

    & a {
        color: white !important;
    }
`

function OSHA() {
    const cookies = new Cookies()

    const [gptText, setGptText] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [questionText, setQuestionText] = useState('')
    const [prevQuestionText, setPrevQuestionText] = useState('')
    const [supportingDocs, setSupportingDocs] = useState([])


    // useEffect(() => {
    //   // Update the document title using the browser API
    //   document.title = `You clicked ${count} times`;
    // });

    function trigger_api() {

        if (questionText != prevQuestionText) {
            setPrevQuestionText(questionText)
            setIsLoading(true)
            setGptText("")
            setSupportingDocs("")
            fetch('https://api.ai.workerscan.com/Stage/ai/osha/v1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies.get('API_Authorization'),
                },
                body: JSON.stringify({
                    question: questionText,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    var doc_list = ""
                    data.supporting_docs.forEach(doc => {
                        var doc_link = doc.replace('s3://workerscan-ai-osha-documents/', 'https://www.osha.gov/sites/default/files/publications/')
                        // doc_list += doc_list + "<br/><a href='" + doc_link + "' target='_blank'>" + doc_link + "</a>"
                        doc_list += doc_list + `<br/>` + doc_link
                    })
                    setGptText(data.response)
                    setSupportingDocs(`<br/>SUPPORTING DOCUMENTS:` + doc_list)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                OSHA Document AI
            </header>
            <div className="App-body">

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>

                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Form className="App-form">
                                <Form.Group style={{ width: "100%" }}>
                                    <Form.Field inline style={{ width: "100%" }}>
                                        <Input style={{ width: "100%" }} placeholder='Type a question' size='big' onChange={e => setQuestionText(e.target.value)} />
                                        <Button disabled={isLoading} icon='search' size='big' onClick={trigger_api}>Search</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={3}>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>

                        </Grid.Column>
                        <Grid.Column width={10}>
                            {isLoading ? <Loader active inline='centered' indeterminate size="big" inverted /> : null}
                            <WriteLikeChatGPT text={gptText} />
                        </Grid.Column>
                        <Grid.Column width={3}>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>

                        </Grid.Column>
                        <Grid.Column width={10}>
                            {supportingDocs.toString().split("<br/>").join("\n")}
                        </Grid.Column>
                        <Grid.Column width={3}>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}

export default OSHA;