import Cookies from 'universal-cookie'
import React, {useState, useEffect} from 'react';
import WriteLikeChatGPT from 'write-like-chat-gpt';
import { Input, Button, Form, Loader } from 'semantic-ui-react'
import styled from 'styled-components'



export const PrimaryColor = '#b51f27'
export const PrimaryButton = styled(Button)`
    background-color: ${PrimaryColor} !important;
    color: white !important;

    & a {
        color: white !important;
    }
`

function WorkerScanAI() {
    const cookies = new Cookies()

    const [gptText, setGptText] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [questionText, setQuestionText] = useState('')
    const [prevQuestionText, setPrevQuestionText] = useState('')

    // useEffect(() => {
    //   // Update the document title using the browser API
    //   document.title = `You clicked ${count} times`;
    // });

    function trigger_api() {

        if (questionText != prevQuestionText) {
            setPrevQuestionText(questionText)
            setIsLoading(true)
            setGptText("")
            fetch('https://api.ai.workerscan.com/Stage/ai/query/v1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies.get('API_Authorization'),
                },
                body: JSON.stringify({
                    question: questionText,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    setGptText(data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setIsLoading(false)
                });
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                WorkerScan AI
            </header>
            <div className="App-body">
                <Form className="App-form">
                    <Form.Group style={{width: "100%"}}>
                        <Form.Field inline style={{width: "100%"}}>
                            <Input style={{width: "100%"}} placeholder='Type a question' size='big' onChange={e => setQuestionText(e.target.value)} />
                            <Button disabled={isLoading} icon='search' size='big' onClick={trigger_api}>Search</Button>
                        </Form.Field>
                    </Form.Group>
                </Form>
                {isLoading ? <Loader active inline='centered' indeterminate size="big" inverted /> : null}
                <WriteLikeChatGPT text={gptText} />
            </div>
        // </div>
    );
}

export default WorkerScanAI;