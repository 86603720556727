// import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";
import WorkerScanAI from './pages/workerscanai';
import OSHA from './pages/osha';
import { Header, Icon, Image, Menu, Segment, Sidebar } from 'semantic-ui-react'
import Cookies from 'universal-cookie'

function App() {
  const styleLink = document.createElement('link')
  styleLink.rel = 'stylesheet'
  styleLink.href =
    'https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css'
  document.head.appendChild(styleLink)

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<WorkerScanAI />} />
          <Route path="/osha" element={<OSHA />} />
          {/* <Route path="dashboard" element={<Dashboard />} /> */}

          {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
      </Routes>
    </Router>
  )
}

function Layout() {
  const [menuVisible, setMenuVisible] = useState(false)
  const cookies = new Cookies()

  const updateMenu = () => {
    setMenuVisible(!menuVisible)
  }
  const hideMenu = () => {
    setMenuVisible(false)
  }

  useEffect(() => {
    checkLogin();
  }, []);

  function checkLogin() {
    fetch('https://api.ai.workerscan.com/Stage/ai/capabilities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': cookies.get('API_Authorization'),
      },
      body: JSON.stringify({}),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data == "Invalid authoization") {
          window.location.href = "https://auth.workerscan.com/login?client_id=4i0on7avge4se61g1bl8g75obe&response_type=token&redirect_uri=https://app.workerscan.com/login-redirect.html";
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <div>

      <Sidebar.Pushable as={Segment}>
        <div class="App-menu" >

          <Icon link name='list' size="big" onClick={updateMenu} />
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            inverted
            vertical
            visible={menuVisible}
            width='thin'
          >
            <Menu.Item as='a'>
              <Icon link name='list' size="big" onClick={updateMenu} />
            </Menu.Item>
            <Menu.Item as='a' onClick={updateMenu}>
              <Link to="/">
                <Icon name='home' />
                WorkerScanAI
              </Link>
            </Menu.Item>
            <Menu.Item as='a' onClick={updateMenu}>
              <Link to="/osha">
                <Icon name='medkit' />
                OSHA
              </Link>
            </Menu.Item>
            {/* <Menu.Item as='a'>
            <Icon name='camera' />
            Channels
          </Menu.Item> */}
          </Sidebar>
        </div>
        <Sidebar.Pusher onFocus={hideMenu}>
          {/* <Segment basic> */}
          {/* <Header as='h3'>Application Content</Header>
            <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png' /> */}
          <Outlet />
          {/* </Segment> */}
        </Sidebar.Pusher>
      </Sidebar.Pushable>

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}

    </div >
  );
}

export default App;
